import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import $ from "jquery";
import Cookies from "js-cookie";

import './index.scss';

import Modal from '@material-ui/core/Modal';
import FormField from '../../../components/utilities/FormField';
import SelectField from '../../../components/utilities/SelectField';

function getModalStyle() {
    return {
        top: `100px`,
        left: `5%`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

const FieldOfInterest = [
    {
        label:'Creative',
        value:'Creative'
    },
    {
        label:'Interactive',
        value:'Interactive'
    },
    {
        label:'P.R.',
        value:'P.R.'
    },
    {
        label:'Social Media',
        value:'Social Media'
    },
    {
        label:'Account Services',
        value:'Account Services'
    },
    {
        label:'Advertising Service',
        value:'Advertising Service'
    },
    {
        label:'Advertising Agency',
        value:'Advertising Agency'
    },
    {
        label:'Business Development',
        value:'Business Development'
    },
    {
        label:'Other',
        value:'Other'
    }
];

const yearsExperience = [
    {
        label:'0-3',
        value:'0-3'
    },
    {
        label:'4-7',
        value:'4-7'
    },
    {
        label:'8-10',
        value:'8-10'
    },
    {
        label:'11+',
        value:'11+'
    }
];

class SimpleFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.submit = this.submit.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.interestChange = this.interestChange.bind(this);
        this.experienceChange = this.experienceChange.bind(this);
        this.resumeChange = this.resumeChange.bind(this);
    }

    state = {
        open: false,
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        contactPhone: '',
        contactInterest: '',
        contactExperience: '',
        contactResume: ''
    };

    submit(e) {
        e.preventDefault();
        let formObject = $('#careerForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        console.log(post_data);

        this.setState({
            contactFirstName: '',
            contactLastName: '',
            contactEmail: '',
            contactPhone: '',
            contactInterest: '',
            contactExperience: '',
            contactResume: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                let r = data[0];
                $('#careerForm').hide();
                $('.form-wrapper .thankyou').show();
                formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                console.log('success', data);
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    firstNameChange(e){
        this.setState({contactFirstName: e.target.value})
    }

    lastNameChange(e){
        this.setState({contactLastName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    interestChange(e){
        this.setState({contactInterest: e.target.value})
    }

    experienceChange(e){
        this.setState({contactExperience: e.target.value})
    }

    resumeChange(e){
        this.setState({contactResume: e.target.value})
    }

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    className="formModal"
                >
                    <div style={getModalStyle()} className={classes.paper+' modalBox form-wrapper'}>
                        <h2>Interested in working for Merit Mile?</h2>
                        <form id="careerForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                            <FormField fieldID={"firstname"} valChange={this.firstNameChange} val={this.state.contactFirstName} type={"text"}  name={"firstname"} label={"First Name"}/>
                            <FormField fieldID={"lastname"} valChange={this.lastNameChange} val={this.state.contactLastName} type={"text"}  name={"lastname"} label={"Last Name"}/>
                            <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                            <FormField fieldID={"phone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"phone"} label={"Phone"}/>
                            <SelectField fieldID={"interest"} valChange={this.interestChange} val={this.state.contactInterest} label={"Field of Interest?"} name={"interest"} values={FieldOfInterest}/>
                            <SelectField fieldID={"experience"} valChange={this.experienceChange} val={this.state.contactExperience} label={"Years of Experience?"} name={"experience"} values={yearsExperience}/>
                            <FormField fieldID={"resume"}  valChange={this.resumeChange} val={this.state.contactResume} type={"multiline"}  name={"resume"} label={"Please copy and paste your resume"}/>
                            <button type="submit" className="btn btn-white">Submit Resume</button>
                            <div className="form-group">
                                <input type="hidden" name="auth" value="53b6099f-67d0-4af3-8271-7fe7626953e7" />
                                <input type="text" className="d-none hu" id="human-check" name="human-check" />
                                <div className="feedback"></div>
                                <div className="fix"></div>
                            </div>
                        </form>
                        <div className="thankyou">
                            <h4>Thank you for your submission.</h4>
                            <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

SimpleFormModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleFormModal);